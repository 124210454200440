import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import { Container } from 'semantic-ui-react'

import 'semantic-ui-less/semantic.less'

// const LinkedItem = ({ children, ...props }) => (
//   <Menu.Item as={Link} activeClassName='active' {...props}>{children}</Menu.Item>
// )

const Layout = ({ children, data }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            { name: 'description', content: 'Developer of DOOMTROOPER CCG and small horror titles. Los Angeles CA based Game Developer' },
            { name: 'keywords', content: 'Doomtrooper, SCL, Secret Cow Level, CCG, Javascript, Cardinal, Web Development, Game Development, Dev, Indie, Los Angeles' },
          ]}
        />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            height: '50px',
            backgroundColor: 'black',
            color: 'white',
            paddingLeft: '15px'
          }}
        >
          SECRET COW LEVEL
    </div>
        <Container>
         {children}
        </Container>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
